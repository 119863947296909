export const VALIDATION_REQUIRED_MESSAGE: string = 'This field is required';
export const VALIDATION_REQUIRED_EMAIL_MESSAGE: string = 'Email is required';
export const VALIDATION_REQUIRED_ORGANIZATION_NAME_MESSAGE: string = 'Community name is required';
export const VALIDATION_REQUIRED_ACCOUNT_TYPE_MESSAGE: string = 'Account type is required';
export const VALIDATION_REQUIRED_FULL_NAME_MESSAGE: string = 'Full name is required';
export const VALIDATION_REQUIRED_PASSWORD_MESSAGE: string = 'Password is required';
export const VALIDATION_REQUIRED_PASSWORD_CONFIRMATION_MESSAGE: string = 'Password confirmation is required';
export const VALIDATION_REQUIRED_EMAIL_CONFIRMATION_MESSAGE: string = 'Email confirmation is required';
export const VALIDATION_INVALID_PASSWORD_MESSAGE: string = 'Password does not meet security criteria';
export const VALIDATION_INVALID_EMAIL_MESSAGE: string = 'Please enter a valid email';
export const VALIDATION_INVALID_PHONE_MESSAGE: string = 'Please enter a valid phone number';
export const VALIDATION_INVALID_PASSWORD_CONFIRM_MESSAGE: string = 'Passwords do not match';
export const VALIDATION_INVALID_EMAIL_CONFIRM_MESSAGE: string = 'Emails do not match';
export const VALIDATION_INVALID_NEW_PASSWORD_MESSAGE: string = 'New password should differ';
export const VALIDATION_MAX_LENGTH_MESSAGE = (field: string): string => `${field} must be shorter than or equal to \${max} characters`;
export const VALIDATION_FIELD_REQUIRED_MESSAGE = (field: string): string => `${field} is required`;
export const PASSWORD_REGEX = /(?=[A-Za-z0-9!@#$%^&*()_+\-=[\]{};':"\\|,.<>~/`?]+$)^(?=.*[a-z])(?=.*[A-Z])((?=.*[0-9])|(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>~/`?]))(?=.{8,}).*$/g;
export const SPECIAL_CHARS_REGEX = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?\d]+/;
export const LOWERCASE_REGEX = /[a-z]/;
export const UPPERCASE_REGEX = /[A-Z]/;
export const CUSTOMER_CARE_EMAIL = 'customercare@workerspring.com';
export const ALLOW_SCHEDULE_APPROVAL_INTRODUCTORY_SESSION = false;
export const PRIVACY_POLICY_URL = 'https://workerspring.com/privacy-policy/';
export const WRONG_FILE_FORMAT = 'Unsupported file format';
export const VALIDATION_MIN_MESSAGE = (field: string, number: number | string): string => `${field} must be greater than or equal to ${number}`;
export const VALIDATION_MAX_MESSAGE = (field: string, number: number | string): string => `${field} must be less than or equal to ${number}`;
export const DATE_TIME_FORMAT = "PP 'at' p";
export const LINK_REGEX = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.%]+$/;
export const VALIDATION_INVALID_LINK_MESSAGE: string = 'Please enter a valid link';
export const FULL_ADDRESS_INVALID_MESSAGE = 'Cannot get location. Please check the entered address';
export const TERMS_OF_USE_URL = 'https://workerspring.com/terms/';
export const DEFAULT_OPPORTUNITY_FILTER_RADIUS = 30;
export const VALIDATION_INVALID_PHONE_CONFIRM_MESSAGE = 'Phones do not match';
export const OAuthUserNotFoundErrorCode = 'OAuthUserNotFoundErrorCode';
export const AppleHideEmailErrorCode = 'AppleHideEmailErrorCode';
export const FILE_UPLOAD = {
  IMPORT_FILE_PATH_REGEX: '(.*?).(csv|xls|xlsx)$',
  IMAGE_FILE_PATH_REGEX: '(.*?).(jpeg|jpg|png)$',
  DOCUMENT_FILE_PATH_REGEX: '(.*?).(doc|docx|pdf)$',

  IMPORT_ALLOWED_MIME_TYPES: ['text/csv', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'],
  IMAGE_ALLOWED_MIME_TYPES: ['image/jpeg', 'image/png'],
  DOCUMENT_ALLOWED_MIME_TYPES: ['application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/pdf'],

  IMPORT_VALIDATION_RULES: 'CSV or XLS file',
  IMAGE_VALIDATION_RULES: 'JPGs, JPEGs and PNGs',
  DOCUMENT_VALIDATION_RULE: 'DOC, DOCX and PDF up to 30Mb',

  IMPORT_MAX_FILE_SIZE: 1048576 * 30, //30MB
  IMAGE_MAX_FILE_SIZE: 1048576 * 10, //10MB
  DOCUMENT_MAX_FILE_SIZE: 1048576 * 30, //30MB

  IMPORT_WRONG_FILE_SIZE: 'File size exceeds 30Mb',
  IMAGE_WRONG_FILE_SIZE: 'File size exceeds 10Mb',
  DOCUMENT_WRONG_FILE_SIZE: 'File size exceeds 30Mb',
};
export const SUBSCRIPTION_SERVICES_URL = 'https://workerspring.com/subscription-services-agreement/';
export const MAIN_URL = 'https://workerspring.com/';
export const EMPLOYER_PLANS_PAGE_URL = 'https://www.workerspring.com/employer-plans';
export const POST_A_JOB_URL = 'https://workerspring.typeform.com/postajob';
export const VALIDATION_ONLY_NUMBER = 'This field can be only a number';
export const VALIDATION_POSITIVE_NUMBER = 'This field must be a positive number';
export const JOIN_NOW_URL = 'https://workerspring.typeform.com/joinnow';
export const UNLOCK_PROGRAMS = 'https://workerspring.typeform.com/unlock-programs';
